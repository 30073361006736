<template>
  <div class="relative row" v-show="legend">
    <div class="legend col-sm-4">
      <div class="legendLabel" v-for="leg of legend" :key="leg.label"><div class="boxLegen" :style="'background:'+leg.color"></div><strong>{{leg.label}}</strong>: {{leg.count}}</div>
    </div>
    <div
      class="col-sm-8"
      ref="chartdivmult"
    ></div>
  </div>
</template>

<script>
// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import { useTheme, create, Label, color } from '@amcharts/amcharts4/core'
import { PieChart, PieSeries } from '@amcharts/amcharts4/charts'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated'

useTheme(am4themesAnimated)

export default {
  name: 'Circle-Chart-Mult',
  props: {
    total: {
      type: Number,
      default: 100,
    },
    size: {
      type: Number,
      default: 50,
    },
    goalData: {
      type: Array,
      default: function () {
        return [{ label: '', count: 0, color: '#D6C820' }]
      },
    },
  },
  data () {
    return {
      chart: null,
      legend: [],
    }
  },
  mounted () {
    setTimeout(() => {
      this.setupChart()
    }, 1500)
  },
  beforeDestroy () {
    if (this.chart) {
      this.chart.dispose()
    }
  },
  methods: {
    setupChart () {
      var colors = [
        { name: '#D6C820' },
        { name: '#35BB15' },
        { name: '#21D9C5' },
        { name: '#1774D1' },
        { name: '#7717D1' },
        { name: '#D1172E' },
        { name: '#0BF5A3' },
        { name: '#360606' },
        { name: '#82EEA1' },
        { name: '#070785' },
        { name: '#171DD1' },
        { name: '#D117CE' },
        { name: '#D1177A' },
        { name: '#077511' },
        { name: '#83550B' },
      ]
      const chart = create(this.$refs.chartdivmult, PieChart)
      chart.data = []
      chart.responsive.enabled = true
      // Add label
      chart.innerRadius = this.size
      const label = chart.seriesContainer.createChild(Label)
      label.horizontalCenter = 'middle'
      label.verticalCenter = 'middle'
      label.textAlign = 'middle'
      label.fontSize = 10

      // Add and configure Series
      const pieSeries = chart.series.push(new PieSeries())
      pieSeries.dataFields.value = 'count'
      pieSeries.dataFields.category = 'label'
      pieSeries.colors.list = [
        color('#D6C820'),
        color('#35BB15'),
        color('#21D9C5'),
        color('#1774D1'),
        color('#7717D1'),
        color('#D1172E'),
        color('#0BF5A3'),
        color('#360606'),
        color('#82EEA1'),
        color('#070785'),
        color('#171DD1'),
        color('#D117CE'),
        color('#D1177A'),
        color('#077511'),
        color('#83550B'),
      ]
      const slice = pieSeries.slices.template
      // slice.tooltipText = ''
      slice.states.getKey('hover').properties.scale = 1
      slice.states.getKey('active').properties.shiftRadius = 0
      chart.data = this.goalData
      for (let i = 0; i < this.goalData.length; i++) {
        this.legend.push({
          label: this.goalData[i].label,
          count: this.goalData[i].count,
          color: colors[i].name,
        })
      }
      // Set up heat legend
      this.$refs.chartdivmult.style.height = (this.size * 3) + 'px'
    },
  },
}
</script>

<style scoped>
.pie-chart {
  width: 100%;
  height: 100px;
}
</style>
